import React from "react"

const releases = [
  {
    url: "https://bit.ly/PrayFantazia",
    title: "Pray / Fantazia",
    label: "Stress Records",
    date: "11/09/20",
    cover: "https://i1.sndcdn.com/artworks-CZmUXhmbeMW6-0-t500x500.jpg",
    tracklist: [{ number: "1", title: "Pray" }, { number: "2", title: "Fantazia" }],
  },
  {
    url: "https://nem.lnk.to/GenerationZ",
    title: "VA Generation Z EP",
    label: "Nothing Else Matters",
    date: "20/04/20",
    cover: "https://i1.sndcdn.com/artworks-ZxW0QPqErnw8e3og-Y8xICw-t500x500.jpg",
    tracklist: [{ number: "2", title: "Elevate" }],
  },
  {
    url: "https://t.co/ZnQ6aBFkDD",
    title: "Tierra EP",
    label: "Gruuv",
    date: "20/12/19",
    cover: "https://i.postimg.cc/p5Mzy5nT/GRU102.jpg",
    tracklist: [
      { number: "1", title: "The Warehouse" },
      { number: "2", title: "Teirra" },
      { number: "3", title: "Body Realm" },
      { number: "4", title: "Back For More" },
    ],
  },
  {
    url: "https://t.co/ZnQ6aBFkDD",
    title: "Astral EP",
    label: "ONNE",
    date: "24/05/19",
    cover: "https://i.postimg.cc/30jpGrgZ/ONNE001.jpg",
    tracklist: [
      { number: "1", title: "Astral Travel" },
      { number: "2", title: "Area Seven" },
      { number: "3", title: "Voids" },
    ],
  },
]

const Music = ({ siteTitle }) => (
  <div className="flex flex-col justify-between mb-8 flex-wrap md:flex-no-wrap m-auto">
    <h3 className="uppercase font-bold text-4xl mb-4"> Releases</h3>

    {releases.map((item, key) => (
      <div id="release" className="flex flex-row">
        {/* COVER IMAGE */}
        <a href={item.url} className="w-4/12 mr-4">
          <img src={item.cover} />
        </a>

        {/*  TEXT */}
        <div id="release-details" class="h-full md:w-48 ">
          <p className="font-bold">{item.title}</p>
          <p>{item.label}</p>

          <div id="tracklist">
            {item.tracklist.map((item, key) => (
              <p>
                {item.number}. {item.title}
              </p>
            ))}
          </div>
        </div>
      </div>
    ))}
  </div>
)

export default Music
