import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Motion from "../images/motion.jpg"

const releases = [
  {
    url:
      "https://soundcloud.com/becauseofart/because-of-art-live-from-cocoon-at-motion-bristol",
    title: "Cocoon Bristol",
    location: "Motion Bristol",
    cover: "https://i.postimg.cc/Wh2kgRXb/cocoon.jpg",
  },
  {
    url: "https://soundcloud.com/motionbristol/road-to-inmotion-because-of-art",
    title: "In:Motion Mix Series",
    location: "YARD Bristol",
    cover: "https://i.postimg.cc/crX8cDb7/RTIM.jpg",
  },
]

const TodaysDate = new Date()

const Music = ({ siteTitle }) => (
  <div className="flex flex-col justify-between mb-8 flex-wrap md:flex-no-wrap m-auto">
    <h3 className="uppercase font-bold text-4xl mb-4"> Mixes</h3>

    {releases.map((item, key) => (
      <div id="release" className="flex flex-row">
        {/* COVER IMAGE */}
        <a href={item.url} className="w-4/12 mr-4">
          <img src={item.cover} />
        </a>

        {/*  TEXT */}
        <div id="release-details" class="h-full md:w-48 ">
          <p className="font-bold">{item.title}</p>
          <p>Recorded: {item.location}</p>
        </div>
      </div>
    ))}
  </div>
)

Music.propTypes = {
  siteTitle: PropTypes.string,
}

Music.defaultProps = {
  siteTitle: ``,
}

export default Music
