import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Music from "../components/releases"
import Mixes from "../components/mixes"

const SecondPage = () => (
  <Layout imageVariation="2">
    <SEO title="Music & Realeases" />
    <Music />
    <Mixes />
  </Layout>
)

export default SecondPage
